<template lang="pug">
  b-container
    b-row
      b-col(cols="12").my-5
        h1.text-center.color-orange Error 404
        h2.text-center.color-orange Página no encontrada
</template>
<script>
  export default {
    name: "NotFound404"
  };
</script>
<style lang="scss" scoped>
  h1 {
    font-size: 6rem !important;
    font-weight: 300 !important;
  }
</style>
